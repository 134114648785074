<template>
  <div class="pill-wrapper" :class="[checked ? 'active' : '']" @click="$emit('statusToggled')"></div>
</template>

<script>

export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.pill-wrapper {
  width: 2.083rem;
  height: 1.042rem;
  padding: 0.19rem;
  box-sizing: border-box;
  background: #35454D;
  position: relative;
  border-radius: 1.04rem;
  transition: all 0.3s;
  cursor: pointer;
}
.pill-wrapper::after {
  content: "";
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: #6F8C9B;
  transition: all 0.3s;
}
.pill-wrapper.active {
  background: var(--color-hover);
}
.pill-wrapper.active::after {
  background: #FFFFFF;
  transform: translateX(calc(2.083rem - 0.625rem - 0.38rem));
}
</style>
