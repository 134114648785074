<template>
  <div class="noti-subtitle-wrapper" v-show="showHeader">
    <span class="noti-subtitle"
      ><span v-if="showNotiPreference">{{ $t("PREFERENCES") }}</span></span
    >
    <Cog
      v-if="isWalkthrough"
      :isStatic="true"
      :isActive="showNotiPreference"
    ></Cog>
    <Cog v-else @click="toggleSetting" :isActive="showNotiPreference"></Cog>
  </div>
  <!-- <Transition name="slide-in" id="NotiPrefContainer"> -->
  <div id="NotiPrefContainer">
    <div class="noti-setting-container" v-if="showNotiPreference">
      <div v-show="!isNotiPanelActive">
        <div
          v-for="setting in preferenceList"
          :key="setting.type"
          class="noti-preference-wrapper"
        >
          <div class="noti-preference-left">
            <img :src="`${s3ImgUrl}/${setting.icon}`" alt="" />
          </div>
          <div class="noti-preference-center">
            <div class="label">{{ setting.label }}</div>
            <div class="description">{{ setting.description }}</div>
          </div>
          <div class="noti-preference-right">
            <TogglePill
              :checked="setting.checked"
              @statusToggled="postUpdateNotiSettingData(setting.type)"
            ></TogglePill>
          </div>
        </div>
      </div>

      <template v-if="isMatchAlertsActive">
        <div v-show="!isNotiPanelActive">
          <div
            class="noti-preference-wrapper noti-preference-sport-type-action"
            @click="notiSportTypeHandler(preference.sportType)"
            v-for="preference in notiPreferenceArr"
            :key="preference.sportType"
          >
            <div class="noti-preference-left icon-noti"><img :src="`${s3ImgUrl}/${preference.icon}`" /></div>
            <div class="noti-preference-center">
              <div class="label-1">{{ preference.label }}</div>
            </div>
            <div class="noti-preference-right">
              <div class="noti-preference-sport-type-right-arrow">
                <img
                  src="../../../../static/images/icons/icon_white_chevron_default_right.png"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div v-show="isNotiPanelActive" class="noti-preference-panel-container">
          <div
            v-for="setting in matchAlertsList"
            :key="setting.type"
            class="noti-preference-wrapper"
          >
            <div class="noti-preference-left"></div>
            <div class="noti-preference-center">
              <div class="label-1">{{ setting.label }}</div>
            </div>
            <div class="noti-preference-right">
              <TogglePill
                :checked="setting.checked"
                @statusToggled="postUpdateNotiSettingData(setting.type)"
              ></TogglePill>
            </div>
          </div>
        </div>
        
      </template>
    </div>
  </div>
  <!-- </Transition> -->
</template>

<script>
const FOOTBALL = "Football";
const BASKETBALL = "Basketball";

import config from "@/js/config.js";
import { mapGetters, mapActions } from "vuex";
import Cog from "@/components/ui/Cog.vue";
import TogglePill from "@/components/ui/TogglePill.vue";

export default {
  props: {
    showHeader: {
      type: Boolean,
      default: false,
    },
    isWalkthrough: {
      type: Boolean,
      default: false,
    },
    parentCloseNotiPanel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Cog,
    TogglePill,
  },
  watch: {
    currentLanguageObj: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && this.showNotiPreference) {
          this.getNotificationSettingData();
        }
      },
    },
    parentCloseNotiPanel: {
      handler(newVal, oldVal) {
        if (newVal)
          this.isNotiPanelActive = false;
      }
    },
    notiPreferenceArr: {
      handler(newVal, oldVal) {
        if (newVal && this.showNotiPreference && this.isNotiPanelActive && this.selectedSportType !== null)
          this.notiSportTypeHandler(this.selectedSportType, true);
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo", "currentLanguageObj"]),
  },
  data() {
    return {
      s3ImgUrl: config.s3ImgUrl,
      showNotiPreference: false,
      preferenceList: [],
      matchAlertsList: [],
      notiPreferenceArr: [],
      isMatchAlertsActive: false,
      isNotiPanelActive: false,
      sportType: {
        football: FOOTBALL,
        basketball: BASKETBALL,
      },
      selectedSportType: null,
    };
  },
  methods: {
    ...mapActions([
      "getNotificationSetting",
      "postUpdateNotiSetting",
      "toastrIconWithBgChangeEvent",
    ]),
    toggleSetting() {
      this.showNotiPreference = !this.showNotiPreference;
      if (this.showNotiPreference) {
        this.getNotificationSettingData();
      }
    },
    toggleMatchAlerts() {
      this.preferenceList.forEach((setting) => {
        if (setting.type === "NS002") {
          this.isMatchAlertsActive = setting.checked ? true : false;
        }
      });
    },
    async getNotificationSettingData() {
      let params = {
        token: this.userInfo.token,
      };
      const result = await this.getNotificationSetting(params);
      let theResult = result.result;
      this.preferenceList = [];
      this.notiPreferenceArr = [];

      for (let i = 0; i < theResult.length; i++) {
        let ele = theResult[i];
        if (ele.type !== null) {
          this.preferenceList.push(ele);
        }
        if (ele.type === null) {
          this.notiPreferenceArr.push({
            label: ele.label,
            icon: ele.icon,
            items: ele.items,
            sportType: ele.groupCode,
          });
        }
      }
     
      if (this.preferenceList.length > 0) {
        this.toggleMatchAlerts();
      } else {
        this.toggleSetting();
      }
    },
    async postUpdateNotiSettingData(type) {
      let params = {
        type,
      };

      this.updatePreferenceList(type);

      const result = await this.postUpdateNotiSetting(params);

      if (result.result.businessCode === 0) {
        this.getNotificationSettingData();
      } else {
        this.updatePreferenceList(type);
      }
    },
    updatePreferenceList(type) {
      this.preferenceList.forEach((setting) => {
        if (setting.type === type) {
          setting.checked = !setting.checked;
          this.preferenceUpdated(setting);
        }

        // if (setting.items.length > 0) {
        //   setting.items.forEach((sItem) => {
        //     if (sItem.type === type) {
        //       sItem.checked = !sItem.checked;
        //       this.preferenceUpdated(sItem);
        //     }
        //   });
        // }
      });

      this.matchAlertsList.forEach(detailSetting => {
        if (detailSetting.type === type) {
          detailSetting.checked = !detailSetting.checked;
          this.preferenceUpdated(detailSetting);
        }
      });

      this.toggleMatchAlerts();
    },
    preferenceUpdated(setting) {
      if (!this.isWalkthrough) {
        let params = {
          isOpen: true,
          msg: "MATCH_ALERTS_UPDATED",
        };

        if (setting.checked) {
          params.type = "success";
          if (setting.type === "NS001") {
            params.msg = "NEWS_ALERT_HAS_BEEN_TURNED_ON";
          } else if (setting.type === "NS002") {
            params.msg = "MATCH_ALERTS_HAVE_BEEN_TURNED_ON";
          }
        } else {
          params.type = "warning";
          if (setting.type === "NS001") {
            params.msg = "NEWS_ALERT_HAS_BEEN_TURNED_OFF";
          } else if (setting.type === "NS002") {
            params.msg = "MATCH_ALERTS_HAVE_BEEN_TURNED_OFF";
          }
        }
        // will always show green tick icon for match update, added by chew (requet from kai sin )
        if (setting.type === "NS001" || setting.type === "NS002") {
        } else {
          params.type = "success";
        }
        this.toastrIconWithBgChangeEvent(params);
      } else if (this.isWalkthrough && setting.checked) {
        // this.$emit("notiPreferenceEnabled", true);
      }
    },
    notiSportTypeHandler(sportType, userToggledLang = false) {
      this.$nextTick(() => {
        if (!userToggledLang)
          this.toggleNotiPanel();
        this.selectedSportType = sportType;
        let matched = this.notiPreferenceArr.filter(ele => ele.sportType === sportType)[0];
        this.matchAlertsList = matched.items;

        this.$emit(
          "isNotiPanelActive",
          this.isNotiPanelActive,
          matched.label,
        );
      });
    },
    toggleNotiPanel() {
      this.isNotiPanelActive = !this.isNotiPanelActive;
    },
  },
};
</script>

<style scoped>
.noti-setting-container {
  min-height: unset;
  max-height: 37rem;
  padding: 1rem 0;
  overflow-y: auto;
}
#NotiPrefContainer {
  /* position: absolute;
  width: 37rem; */
  background-color: var(--color-theme);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  /* z-index: 1; */
  position: relative;
}
.noti-subtitle-wrapper {
  background-color: var(--color-theme);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.813rem 1.25rem 0 1rem;
  display: flex;
  justify-content: space-between;
}
.noti-subtitle {
  font-size: 0.875rem;
  font-weight: 700;
}
.noti-preference-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.noti-preference-left {
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noti-preference-left img {
  width: 1.5rem;
  height: 1.5rem;
}
.noti-preference-center {
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}
.noti-preference-center .label {
  font-size: 0.875rem;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.313rem;
}
.noti-preference-center .label-1 {
  font-size: 0.875rem;
  color: #ffffff;
  font-weight: 400;
  line-height: 1.313rem;
}
.noti-preference-center .description {
  font-size: 0.688rem;
  color: var(--color-grey-80);
  font-weight: 400;
}
.noti-preference-right {
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-in-leave-active,
.slide-in-enter-active {
  transition: all 0.3s ease-in;
}
.slide-in-enter-from,
.slide-in-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(3px);
  opacity: 0;
}
.icon-noti {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1.5rem !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.icon-football {
  background: url("../../../../static/images/icons/icon_notification_football.png");
}
.icon-basketball {
  background: url("../../../../static/images/icons/icon_notification_basketball.png");
}
.noti-preference-sport-type-action {
  cursor: pointer;
}
.noti-preference-sport-type-right-arrow {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: end;
  align-items: center;
}
.noti-preference-sport-type-right-arrow img {
  width: 0.5rem;
  height: auto;
}
.noti-preference-panel-container {
  
}

@media (max-width: 768px) {
  #NotiPrefContainer {
    background: transparent;
  }
  .noti-setting-container {
    padding: 0;
  }
}
</style>
